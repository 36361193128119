<template>
  <v-combobox
    outlined
    :label="$t('site')"
    :placeholder="$t('allSites')"
    multiple
    clearable
    :items="sites"
    @input="
      $emit(
        'filterSites',
        $event.map(s => s.value)
      )
    "
    id="sitesFilter"
    hide-details
  ></v-combobox>
</template>

<script>
export default {
  computed: {
    sites() {
      return this.currentUser.sites.map(s => ({
        text: `${s.siteCode} - ${s.name}`,
        value: s.siteCode
      }));
    }
  },
  i18n: {
    messages: {
      en: {
        site: "Filter on Sites",
        allSites: "All Sites"
      },
      fr: {
        site: "Filtrer sur les Centres",
        allSites: "Tous les sites"
      }
    }
  }
};
</script>
