import {
  QueriesBySites,
  InclusionsBySites,
  NumericalDistribution,
  CategoricalDistribution
} from "spiral";

export class KPIOptions {
  constructor(study) {
    this.queriesBySites = {
      name: "QueriesBySites",
      types: ["matrix", "pies", "histogram"],
      default: "histogram",
      aggregates: ["values", "columnSums"],
      selectable: false
    };
    this.categoricalDisribution = {
      name: "CategoricalDistribution",
      types: ["matrix", "histogram", "pies"],
      default: "histogram",
      aggregates: ["values", "columnSums"],
      selectable: true,
      items: study.kpis
        .filter(pi => pi.type.nature == "categorical")
        .map(p => ({ name: p.kpi, value: p.variableName }))
    };
    this.numericalDistribution = {
      name: "NumericalDistribution",
      types: ["matrix", "boxPlot"],
      default: "boxPlot",
      aggregates: ["values", "columnSums"],
      selectable: true,
      items: study.kpis
        .filter(pi => pi.type.nature == "numerical")
        .map(p => ({ name: p.kpi, value: p.variableName }))
    };
    this.inclusionsBySites = {
      name: "InclusionBySite",
      types: ["matrix", "histogramTimeSeries", "lineTimeSeries"],
      default: "lineTimeSeries",
      aggregates: ["values", "rowSums"],
      selectable: false
    };
  }
}

export default {
  computed: {
    allOptions() {
      return new KPIOptions(this.currentStudy);
    }
  },
  methods: {
    kpiOptions(kpi) {
      if (kpi instanceof QueriesBySites) return this.allOptions.queriesBySites;
      if (kpi instanceof InclusionsBySites)
        return this.allOptions.inclusionsBySites;
      if (kpi instanceof NumericalDistribution)
        return this.allOptions.numericalDistribution;
      if (kpi instanceof CategoricalDistribution)
        return this.allOptions.categoricalDisribution;
    },
    kpiId(kpi) {
      if (kpi instanceof QueriesBySites) return "QueriesBySites";
      if (kpi instanceof InclusionsBySites) return "InclusionsBySites";
      if (kpi instanceof NumericalDistribution)
        return `NumericalDistribution_${kpi.datasource.column.variableName}`;
      if (kpi instanceof CategoricalDistribution)
        return `CategoricalDistribution_${kpi.datasource.column.variableName}`;
    }
  }
};
